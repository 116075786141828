<template>
  <div>
    <a-button
      @click="handleAdd()"
      type="primary"
      style="margin-bottom: 10px;"
      v-auth="auth_equipment2.CONFIG_CREATE_AND_UPDATE"
      >新增指标</a-button
    >
    <a-table
      @change="tableChange"
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :pagination="pagination"
      :rowKey="(record) => record.id"
      :loading="tableLoading"
      bordered
    >
      <template slot="_index" slot-scope="text, record, index">
        {{ searchParams.number * searchParams.size + index + 1 }}
      </template>
      <template slot="metricsConfig.metricsType" slot-scope="metricsType">
        <span v-if="metricsType === 'yc'">遥测</span>
        <span v-if="metricsType === 'yx'">遥信</span>
        <span v-if="metricsType === 'yt'">遥调</span>
        <span v-if="metricsType === 'yk'">遥控</span>
      </template>
      <template slot="core" slot-scope="core">
        {{ core ? '是' : '否' }}
      </template>
      <template slot="metricsConfig.unit" slot-scope="record">
        {{ record ? record : '-' }}
      </template>
      <template slot="metricsConfig.maxValue" slot-scope="record">
        {{ record ? record : '-' }}
      </template>
      <template slot="metricsConfig.minValue" slot-scope="record">
        {{ record !== null ? record : '-' }}
      </template>
      <template slot="metricsConfig.escapeContent" slot-scope="escapeContent">
        <span v-if="escapeContent">
          <a-tag v-if="JSON.parse(escapeContent).zero"> 0: {{ JSON.parse(escapeContent).zero }}</a-tag>
          <span v-else>-</span>
          <a-tag v-if="JSON.parse(escapeContent).one">1:{{ JSON.parse(escapeContent).one }}</a-tag>
          <span v-else>-</span>
        </span>
        <span v-else>--</span>
      </template>
      <span slot="action" slot-scope="text, record">
        <a @click="handleEdit(record.id)" v-auth="auth_equipment2.CONFIG_CREATE_AND_UPDATE">编辑</a>
        <a-divider type="vertical" />
        <a @click="handleDelete(record.id)" v-auth="auth_equipment2.CONFIG_DELETE">删除</a>
      </span>
    </a-table>
    <a-modal v-model="addModal" title="配置" @cancel="closeAddModal" :width="420">
      <config-form
        :categoryId="categoryId"
        :configList="configList"
        :deviceId="searchParams.deviceId"
        :gateWayList="gateWayList"
        ref="addRef"
        type="add"
        @addSuccess="addSuccess"
        @addError="addError"
      ></config-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
          >保存新增
        </a-button>
<!--        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"-->
<!--          >保存复制-->
<!--        </a-button>-->
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
          >保存关闭</a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal" title="配置编辑">
      <config-form
        :categoryId="categoryId"
        :configList="configList"
        :deviceId="searchParams.deviceId"
        :gateWayList="gateWayList"
        ref="editRef"
        type="edit"
        @editSuccess="editSuccess"
        @editError="editError"
      ></config-form>
    </a-modal>
  </div>
</template>

<script>
import { equipmentConfigColums } from './common/common'
import SERVICE_URLS from '@/api/service.url'
import configForm from './configForm'
import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
export default {
  components: { configForm },
  mixins: [entityCRUDWithCopy],
  name: 'localImageForm',
  data() {
    return {
      categoryId: null,
      sup_this: this,
      entityBaseUrl: SERVICE_URLS.equipment2.metricsRealInfoApi,
      initColumns: equipmentConfigColums(),
      searchParams: {
        deviceId: '',
        number: 0,
        size: 10,
      },
      visible: false,
      editVisible: false,
      gateWayList: [],
      configList: [],
    }
  },
  created() {
    this.getGateWayList()
  },
  methods: {
    getGateWayList() {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.metricsRealInfoApi.selectGateWay,
        noTips: true,
        success: (data) => {
          this.gateWayList = data.body
        },
      })
    },
    addSuccess(addType) {
      if (addType === 'copy') {
        // 表示不关闭Modal
        this.addLoading = false
      } else if (addType === 'continue') {
        this.addLoading = false
        this.$refs.addRef.metricsConfig = {}
        this.$refs.addRef.escape = { zero: '', one: '' }
      } else {
        // 直接关闭Modal
        this.addLoading = false
        this.addModal = false
        this.$refs.addRef.metricsConfig = {}
        this.$refs.addRef.escape = { zero: '', one: '' }
      }
      this.$message.success('保存成功')
      this.addLoading = false
      this.search()
    },
  },
}
</script>

<style scoped></style>
