const configVO = () => {
  return {
    id: '',
    device: {
      id: '',
    },
    gateway: {
      id: '',
    },
    metricsConfig: {
      id: '',
    },
  }
}

export default configVO
