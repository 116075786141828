<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">在线设备基本信息</h1>
    </div>
    <statistics ref="statistics" :treeData="treeData" :sup_this="sup_this"></statistics>
    <a-card :bordered="false" style="margin-top: 15px;padding: 0px">
      <a-tabs @change="selectTab">
        <a-tab-pane tab="全部设备" key="zx">
          <div class="content">
            <a-row :gutter="12">
              <a-col :span="5">
                <equipment-type-tree ref="equipmentType" :treeData=treeData :sup_this="sup_this" @deleteSucceed="deleteSucceed"></equipment-type-tree>
              </a-col>
              <a-col :span="19">
                <on-line-equipment ref="onLineEquipment" :sup_this="sup_this" :categoryId="categoryId" :departmentId="departmentId" :orgRadioType="orgRadioType"></on-line-equipment>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
        <a-tab-pane tab="监测设备" key="zxjc">
          <div class="content">
            <a-row :gutter="12">
              <a-col :span="5">
                <equipment-type-tree ref="equipmentType" :treeData=treeData :sup_this="sup_this" type="zxjc" @deleteSucceed="deleteSucceed"></equipment-type-tree>
              </a-col>
              <a-col :span="19">
                <on-line-equipment ref="onLineEquipment" :sup_this="sup_this" :categoryId="categoryId" :departmentId="departmentId" :orgRadioType="orgRadioType"></on-line-equipment>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </page-layout>
</template>
<script>
  import statistics from './statistics/List'
  import equipmentTypeTree from './equipmentTypeTree/List'
  import onLineEquipment from './onLine/List'
  import PageLayout from '@/components/page/PageLayout'

  const treeData = []
  export default {
    name: 'EquipmentInfo',
    components: { statistics, equipmentTypeTree, onLineEquipment, PageLayout },
    data () {
      return {
        sup_this: this,
        treeData,
        categoryId: '',
        departmentId: '',
        orgRadioType: 'orgAll'
      }
    },
    created () {

    },
    methods: {
      selectTab (key) {
        this.$nextTick(() => {
          this.$refs.onLineEquipment.searchParams.type = key
          this.$refs.onLineEquipment.search()
        })
      },
      deleteSucceed () {
        this.categoryId = ''
        this.$refs.onLineEquipment.search()
      }
    }
  }
</script>
<style scoped>

  .head-info {
    border-right: 1px solid rgba(233, 233, 233, 1);
  }

  .head-info > span {
    color: rgba(0, 0, 0, .45);
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  .head-info > p {
    color: rgba(0, 0, 0, .85);
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }

  /*.content{*/
  /*  background: white;*/
  /*  margin-top: 20px;*/
  /*  float: left;*/
  /*  width: 100%;*/
  /*}*/
  .content-left {
    width: 20%;
    float: left;
    border-right: 1px solid rgba(233, 233, 233, 1);
  }

  .content-right {
    width: 80%;
    float: left;
    border-left: 1px solid rgba(233, 233, 233, 1);
  }

  .searchList {
    padding: 20px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
  }

  .tree > .ant-card-wider-padding {
    padding: 0px;
  }

</style>
