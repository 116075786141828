import { render, staticRenderFns } from "./MetricsList.vue?vue&type=template&id=0c460f53&scoped=true&"
import script from "./MetricsList.vue?vue&type=script&lang=js&"
export * from "./MetricsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c460f53",
  null
  
)

export default component.exports