<template>
  <div>
    <div class="searchList">
      <a-form layout="inline">
        <a-form-item label="设备名称">
          <a-input v-model="searchParams.name" placeholder="请输入设备名称"/>
        </a-form-item>
        <a-form-item label="设备编号">
          <a-input v-model="searchParams.no" placeholder="请输入设备编号"/>
        </a-form-item>
        <a-form-item label="安装位置">
          <a-input v-model="searchParams.location" placeholder="请输入安装位置"/>
        </a-form-item>
        <a-form-item class="f-r m-r-none">
          <a-button type="primary" @click="search" icon="search">查询</a-button>
          <a-button class="m-l-6" @click="customResetForm" icon="reload">重置</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="padding: 10px;">
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        bordered
        :columns="onLineColumns"
        @change="tableChange"
        :customRow="clickLookEquipmentInfo"
        :pagination="pagination"
        :dataSource="tableData"
        :loading="tableLoading"
        :scroll="{ x: 1400 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="model" slot-scope="text, record">
          {{ record.model || '-'}}
        </template>
        <template slot="transferType" slot-scope="transferType">
          <span v-if="transferType === 'RK'">入库</span>
          <span v-if="transferType === 'CK'">出库</span>
          <span v-if="transferType === 'ZY'">转移</span>
          <span v-if="transferType === 'TB'">调拨</span>
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="clickExtendInfo(record)" @click.stop>扩展信息</a>
          <a-divider type="vertical"/>
            <a @click="handleEdit(record.id)" v-auth="auth_equipment2.INVENTORY_CREATE_AND_UPDATE" @click.stop>编辑</a>
           <a-divider type="vertical"/>
           <a v-if="record.metricsNumber>0" style="color: #12b352" @click="clickConfig(record)" v-auth="auth_equipment2.CONFIG_VIEW" @click.stop>配置</a>
            <a v-else  @click="clickConfig(record)" v-auth="auth_equipment2.CONFIG_VIEW" @click.stop>配置</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="clickRK(record)" v-auth="auth_equipment2.ONLINE_RK" @click.stop>入库</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="clickZY(record)" v-auth="auth_equipment2.ONLINE_ZY" @click.stop> 转移</a>
          <!--          <a-divider type="vertical" />-->
          <!--                 <a href="javascript:;" @click="clickLocalImage(record)" v-auth="auth_equipment2.IMAGE_CREATE" @click.stop> 现场图片</a>-->
        </span>
      </a-table>
      <a-modal :title="modalTitle + '_入库'" @ok="RKOk" @cancel="closeModal" :visible="RKModal">
        <rk ref="RKRef" @RKSucceed="RKSucceed"></rk>
      </a-modal>
      <a-modal :title="modalTitle + '_转移'" @ok="ZYOk" @cancel="closeModal" :visible="ZYModal">
        <zy ref="ZYRef" @ZYSucceed="ZYSucceed"></zy>
      </a-modal>
      <a-modal :title="modalTitle + '_现场图片'" @ok="localImageOk" @cancel="closeModal" :visible="localImageModal">
        <local-image ref="imageRef" :sup_this="sup_this"></local-image>
      </a-modal>
      <a-modal :title="modalTitle + '_指标'" :footer="null" @cancel="closeModal" :visible="configModal" :width="1200">
        <config ref="configRef" :categoryId="categoryId"></config>
      </a-modal>
    </div>
    <a-modal title="扩展列表" :footer="null" @cancel="closeExtendListModal" :visible="extendInfoModal">
      <ExtendInfo ref="extendInfoRef" :systemModule="systemModule" :instanceId="instanceId" type="add"></ExtendInfo>
    </a-modal>
    <a-modal :width="850" title="编辑" :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal">
      <PutForm
        ref="editRef"
        type="edit"
        @editSuccess="editSuccess"
        @editError="editError"
        :categoryId="categoryId"
        menuType='zx'
      ></PutForm>
    </a-modal>
    <look ref="lookRef"></look>
  </div>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  import { onLineColums } from './common/common'
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import rk from './operation/RK'
  import zy from './operation/ZY'
  import localImage from './operation/localImage'
  import config from './operation/config/configList'
  import look from '../Look'
  import ExtendInfo from '@/components/ExtendInfo/ExtendInfoList'
  import PutForm from '../inventory/Form'

  export default {
    name: 'onLineEquipment',
    components: { rk, zy, localImage, config, look, ExtendInfo, PutForm },
    mixins: [entityCRUD],
    props: {
      categoryId: {
        categoryId: [String, Number],
        required: true
      },
      departmentId: {
        type: [String, Number]
      },
      orgRadioType: {
        type: String
      },
      sup_this: {
        type: Object
      }
    },
    watch: {
      categoryId: function (newVal) {
        this.searchParams.categoryId = newVal
        this.search()
      },
      departmentId: function (newVal) {
        this.searchParams.departmentId = newVal
        this.search()
      },
      orgRadioType: function (newVal) {
        this.searchParams.orgRadioType = newVal
        this.search()
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentInfoApi,
        onLineColumns: onLineColums(),
        initColumns: onLineColums(),
        searchParams: {
          direction: '',
          order: '',
          number: '0',
          size: '10',
          type: 'zx',
          categoryId: this.categoryId,
          name: '',
          no: '',
          location: '',
          departmentId: this.departmentId,
          orgRadioType: this.orgRadioType
        },
        RKModal: false,
        ZYModal: false,
        localImageModal: false,
        configModal: false,
        depotsList: [],
        systemModule: 'device_info',
        instanceId: null,
        extendInfoModal: false,
        modalTitle: ''
      }
    },
    computed: {},
    created () {
    },
    methods: {
      clickRK (record) {
        this.modalTitle = record.name
        this.RKModal = true
        this.$nextTick(() => {
          this.$refs.RKRef.getEquipmentInfo(record.id)
        })
      },
      RKOk () {
        this.$refs.RKRef.handleAddSubmit()
      },
      clickZY (record) {
        this.modalTitle = record.name
        this.ZYModal = true
        this.$nextTick(() => {
          this.$refs.ZYRef.getEquipmentInfo(record.id)
        })
      },
      ZYOk () {
        this.$refs.ZYRef.handleAddSubmit()
        this.closeModal()
      },
      clickLocalImage (record) {
        this.modalTitle = record.name
        this.localImageModal = true
        this.$nextTick(() => {
          this.$refs.imageRef.getEquipmentInfo(record.id)
        })
      },
      localImageOk () {
        this.$refs.imageRef.handleAddSubmit()
        this.closeModal()
      },
      clickConfig (record) {
        this.modalTitle = record.name
        this.configModal = true
        this.$nextTick(() => {
          this.$refs.configRef.searchParams.deviceId = record.id
          this.$refs.configRef.search()
        })
      },
      closeModal () {
        this.RKModal = false
        this.ZYModal = false
        this.localImageModal = false
        this.configModal = false
      },
      customResetForm () {
        this.searchParams = {
          type: 'zx',
          categoryId: this.categoryId,
          name: '',
          no: '',
          location: '',
          number: 0,
          size: 10,
          departmentId: this.departmentId,
          orgRadioType: this.orgRadioType
        }
        this.search()
      },
      clickLookEquipmentInfo (record) {
        return {
          on: {
            click: () => {
              this.$refs.lookRef.visible = true
              this.$nextTick(() => {
                this.$refs.lookRef.getEquipmentInfo(record.id, 'isZX')
              })
            }
          }
        }
      },
      closeExtendListModal () {
        this.extendInfoModal = false
      },
      clickExtendInfo (record) {
        this.modalTitle = record.name
        this.extendInfoModal = true
        this.instanceId = record.id
        this.$nextTick(() => {
          this.$refs.extendInfoRef.search()
        })
      },
      RKSucceed () {
        this.refreshListAndStatistics()
      },
      ZYSucceed () {
        this.search()
      },
      refreshListAndStatistics () {
        this.closeModal()
        this.search()
        this.sup_this.$refs.statistics.loadStatistics()
      }
    }
  }
</script>
