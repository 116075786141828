<template>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="厂家类型">
              <a-select allowClear showSearch v-model="searchParams.type" placeholder="请选择厂家类型" @change="search">
                <a-select-option
                  v-for="(item, index) in companyTypeList"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="网关编号">
              <a-input v-model="searchParams.deviceGatewayNo" @change="search" placeholder="请输入网关编号" />
            </a-form-item>
            <a-form-item label="网关名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入网关名称" />
            </a-form-item>

            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="deviceGatewayNo" slot-scope="text, record">
          {{ record.deviceGatewayNo }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a type="link" @click="selectGateway(record)">选择</a>
        </template>
        <template slot="typeSlot" slot-scope="text, record">
          <span v-if="record.type === 'jk'">GRM云服务器(巨控)</span>
          <span v-if="record.type === 'lyyjkj'">洛阳愿景科技有限公司</span>
          <span v-if="record.type === 'tspsdzjs'">唐山平升电子技术开发有限公司</span>
        </template>
      </a-table>
    </a-card>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import { gatewayColumns, companyTypeList } from './common/common'
  export default {
    name: 'List',
    mixins: [entityCRUD],
    components: { },
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.equipment2.gatewayApi,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          deviceGatewayNo: '',
          name: '',
          type: undefined,
        },
        companyTypeList: companyTypeList(),
        initColumns: gatewayColumns(),
      }
    },
    methods: {
      selectGateway(data) {
        this.$emit('gatewaySelect', data)
      }
    },
  }
</script>
