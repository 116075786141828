<template>
  <a-row :gutter="12">
    <a-col :span="4">
      <a-tree :treeData="treeData" :showLine="true" v-if="treeData.length" :defaultExpandedKeys="['0-0']" @select="selectTypeTree" :defaultSelectedKeys="['0-0']">
      </a-tree>
    </a-col>
    <a-col :span="20">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="中文名称">
              <a-input v-model="searchParams.cnName" placeholder="请输入中文名称" allowClear/>
            </a-form-item>
            <a-form-item label="英文名称">
              <a-input v-model="searchParams.enName" placeholder="请输入英文名称" allowClear/>
            </a-form-item>
            <a-form-item label="指标编码">
              <a-input v-model="searchParams.code" placeholder="请输入指标编码" allowClear/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button class="m-l-6" type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        @change="tableChange"
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="metricsType" slot-scope="metricsType">
          <span v-if="metricsType === 'yc'">遥测</span>
          <span v-if="metricsType === 'yx'">遥信</span>
          <span v-if="metricsType === 'yt'">遥调</span>
          <span v-if="metricsType === 'yk'">遥控</span>
        </template>
        <template slot="core" slot-scope="core">
          {{ core ? '是' : '否' }}
        </template>
        <template slot="unit" slot-scope="record">
          {{ record ? record : '-' }}
        </template>
        <template slot="maxValue" slot-scope="record">
          {{ record ? record : '-' }}
        </template>
        <template slot="minValue" slot-scope="record">
          {{ record !== null ? record : '-' }}
        </template>
        <template slot="escapeContent" slot-scope="escapeContent">
          <span v-if="escapeContent">
          <a-tag v-if="JSON.parse(escapeContent).zero"> 0: {{JSON.parse(escapeContent).zero}}</a-tag>
            <span v-else>-</span>
          <a-tag v-if="JSON.parse(escapeContent).one">1:{{JSON.parse(escapeContent).one }}</a-tag>
             <span v-else>-</span>
            </span>
          <span v-else>--</span>
        </template>
        <span slot="action" slot-scope="text, record">
          <a @click="metricsSelect(record)" v-auth="auth_equipment2.CONFIG_DELETE">选择</a>
        </span>
      </a-table>
    </a-col>
  </a-row>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import { configColums } from './common/common'

  export default {
    components: {  },
    mixins: [entityCRUDWithCopy],
    name: 'localImageForm',
    data () {
      return {
        categoryId: null,
        sup_this: this,
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentConfigApi,
        tableData: [],
        initColumns: configColums(),
        searchParams: {
          categoryId: undefined,
          cnName: '',
          code: '',
          enName: ''
        },
        categoryList: [],
        treeData: [],
      }
    },
    created () {
      this.getCategoryTree()
    },
    methods: {
      getCategoryTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.categoryList.push(data.body)
          }
        })
        this.$http(this, {
          url: SERVICE_URLS.equipment2.metricsType.getTree,
          noTips: true,
          success: (data) => {
            this.treeData.push(data.body)
          }
        })
      },
      selectTypeTree(selectedKeys) {
        if (selectedKeys[0] === '0-0') {
          this.searchParams.typeId = undefined
        } else {
          this.searchParams.typeId = selectedKeys[0]
        }
        this.search()
      },
      metricsSelect(data) {
        this.$emit('metricsSelect', data)
      }
    }
  }
</script>

<style scoped></style>
